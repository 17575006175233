






























































































































import { InterestCalculTypeReadable } from '@/store/models/Product'
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import Organization from '../../../../../store/models/Organization'

export default Vue.extend({
  data() {
    return {
      /* modeCalcules: [
        {
          field: 'nominal',
          label: 'Nominal'
        },
        {
          field: 'degressif',
          label: 'Dégressif'
        }
      ], */
      form: {
        name: '',
        reference: '',
        organizationId: null,
        durationInMonth: 0,
        annualRate: 0,
        effectDate: '',
        deferred: 0,
        graceDelay: 0,
        modeCalcul: 'nominal'
      }
    }
  },

  computed: {
    ...mapGetters('entities/credit_line_provisions', ['newProvision']),
    ...mapGetters('product', ['getCurrentProduct']),

    sfds(): Organization[] {
      return Organization.query()
        .whereIdIn(this.getCurrentProduct.sfdConcern)
        .where('type', 'sfd')
        .get()
    },

    sfdsOptions(this: any) {
      return this.sfds.map((sfd: Organization) => ({
        field: sfd.id,
        label: sfd.name
      }))
    },

    loanValidation(this: any) {
      return {
        minValue: this.getCurrentProduct.creditDetails.sfdLoanMinDuration,
        maxValue: this.getCurrentProduct.creditDetails.sfdLoanMaxDuration,
        multipleValue: this.getCurrentProduct.reimbursementDetails
          .sfdPaymentPeriodicity
      }
    },

    deferedValidation(this: any) {
      return {
        minValue: this.getCurrentProduct.reimbursementDetails
          .sfdDeferredPaymentMinDuration,
        maxValue: this.getCurrentProduct.reimbursementDetails
          .sfdDeferredPaymentMaxDuration,
        multipleValue: this.getCurrentProduct.reimbursementDetails
          .sfdPaymentPeriodicity
      }
    },

    graceDelayValidation(this: any) {
      return {
        minValue: this.getCurrentProduct.reimbursementDetails
          .sfdGraceDelayMinDuration,
        maxValue: this.getCurrentProduct.reimbursementDetails
          .sfdGraceDelayMaxDuration,
        multipleValue: this.getCurrentProduct.reimbursementDetails
          .sfdPaymentPeriodicity
      }
    },

    interestCreditRate(this: any) {
      return this.getCurrentProduct.reimbursementDetails.sfdInterestRate
    },

    allowDeferredPaymentForSfd(this: any) {
      return this.getCurrentProduct.reimbursementDetails
        .allowDeferredPaymentForSfd
    },

    allowGraceDelayForSfd(this: any) {
      return this.getCurrentProduct.reimbursementDetails.allowGraceDelayForSfd
    }
  },

  created() {
    Organization.api().fetch()
    this.form = this.newProvision
  },

  mounted(this: any) {
    if (this.$route.params.error) {
      const error = this.$route.params.error as any
      Object.keys(error.constraints).forEach((rule: string) => {
        switch (rule) {
          case 'isUnique':
            this.$refs.validator.setErrors({
              [error.property]: ['Cette valeur est déjà utilisée']
            })
            break
        }
      })
    }

    this.form.modeCalcul =
      InterestCalculTypeReadable[
        this.getCurrentProduct.creditDetails.sfdInterestCalculMode
      ] || this.getCurrentProduct.creditDetails.sfdInterestCalculMode
    this.form.annualRate = Number(
      this.getCurrentProduct.reimbursementDetails.sfdInterestRate
    )
  },

  methods: {
    ...mapActions('entities/credit_line_provisions', [
      'updateNewProvisionInfos'
    ]),

    onSubmit() {
      this.updateNewProvisionInfos(this.form)

      this.$router.push({
        name: 'creditlines.create.deadlines',
        params: { otm: 'mtn' }
      })
    }
  }
})
